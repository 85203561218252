.clearfix {
  display: table;
  content: "";
  clear: both;
}

.App {
  background: #f9f9ff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  text-align: center;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.App-content {
  max-width: 980px;
  margin: auto;
  display: block;
}

p {
  font-size: 18px;
}

ul {
  list-style: none;
}

ul li {
  margin-bottom: 5px;
}

.card {
  padding: 5px 15px 5px;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px 0px;
  margin-bottom: 15px;
  margin-top: 15px;
  background: #fff;
}

.card h3 {
  margin-top: 5px;
} 

.badge {
  background: #3880ff;
  color: #fff;
  font-weight: 500;
  border-radius: 31px;
  padding: 4px 10px 5px;
  margin: 0px 9px 9px 0;
  display: inline-block;
}

.App-link {
  color: #61dafb;
}

.card-experience {
  padding-bottom: 15px;
    margin-bottom: 15px;
    box-shadow: rgb(33 35 38 / 10%) 0px 10px 10px -10px;
}

.card-experience:last-child { 
  box-shadow: none;
}

.card-experience .txt-time {
  color: gray;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.card-experience h6 {
  margin: 5px 0;
  font-size: 16px;
  font-weight: normal;
  color: rgb(56, 128, 255);
}

.card-experience p {
  margin-top: 5px;
}

.light-room-wrapper > div > div:nth-child(2) {
  position: fixed;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
